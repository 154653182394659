/**
 * This is the main module page for AmpverseSDK usage.
 * This module has an only main class, {@link AmpverseSDK}, acting as
 * an interface for developers to use any features or APIs.
 *
 * @example
 * Firstly, we have to initialize the SDK by the following code
 * ```ts
 * // To initialize the SDK
 * await AmpverseSDK.init({ appId: 'your-app-id' });
 * ```
 * Note that `your-app-id` should be replaced by the actual appId provided by your super app provider.
 *
 * Once the initialization above is successful, you could use any APIs inside {@link AmpverseSDK}.
 *
 * @packageDocumentation
 */
import getConfig, { AppConfig } from './config'
import { UserService } from './services'
const { version } = require('../package.json')

/**
 * Class representing AmpverseSDK.
 * This class has no constructor, could not be initialized by `new` operator.
 * However, we provide {@link AmpverseSDK.init} method to initialize the SDK.
 */
class AmpverseSDK {
  private static appId: string
  private static userService: UserService
  private static config: typeof AppConfig
  public static platform: MobilePlatform
  protected static mobileEvents = new Map<BindNativeEvents, Function>()
  public static version = version

  /**
   * Initializes the Mini App SDK.
   * @param option - An object containing initialization options.
   * @example
   * ```ts
   * await AmpverseSDK.init({ appId: "your-app-id" });
   * ```
   * @group Initialization Method
   * @throws Error something went wrong.
   */
  public static async init(option: AmpverseSDKInitParams) {
    // APP_ENV is replaced (not assign) with value passed from build.js
    const { appId } = option

    console.log('process?.env?.APP_ENV', process?.env?.APP_ENV)
    // @ts-ignore
    this.config = getConfig(process?.env?.APP_ENV)

    this.appId = appId

    // initialize all services
    this.userService = new UserService({
      baseURL: this.config.GATEWAY_BASE_URL,
      timeout: 5000,
      appId: this.appId,
    })
  }

  /**
   * Tracks login event.
   * @param loginData - Data payload for login tracking.
   * @returns A promise that resolves when the tracking is complete.
   * @group Services Methods
   */
  public static trackLogin(loginData: TrackLoginPayload): Promise<unknown> {
    return this.userService.trackLogin(loginData)
  }

  /**
   * Tracks registration event.
   * @param loginData - Data payload for registration tracking.
   * @returns A promise that resolves when the tracking is complete.
   * @group Services Methods
   */
  public static trackRegister(
    loginData: TrackRegisterPayload
  ): Promise<unknown> {
    return this.userService.trackRegister(loginData)
  }
}

export default AmpverseSDK
