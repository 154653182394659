// TODO split files
export class AppConfig {
  public static GATEWAY_BASE_URL = 'https://gateway-api-datawiz.ampverse.com/'
}

class DevelopmentConfig extends AppConfig {
  public static GATEWAY_BASE_URL = 'https://gateway-api-datawiz.ampverse.com/'
}

class ProductionConfig extends AppConfig {
  public static GATEWAY_BASE_URL = 'https://gateway-api-datawiz.ampverse.com/'
}

export default function getConfig(
  env: ConfigENV = 'PRODUCTION'
): typeof AppConfig {
  console.log('getConfig', env)
  switch (env) {
    case 'PRODUCTION':
      return ProductionConfig
    case 'DEVELOPMENT':
      return ProductionConfig
    default:
      return ProductionConfig
  }
}
